import { useCallback, useEffect, useState } from "react";
import { Retry, Status } from "../../../components/retry/Retry";
import { InvestmentAccount, dataAccounts } from "../../../data/dataAccounts";
import {
  dataWithdrawals,
  ExternalAccount,
  ExternalPendingSavingsWithdrawalResponse,
  PeriodicWithdrawal,
  Withdrawal,
} from "../../../data/dataWithdrawals";
import { Page } from "../../Page";
import { ExternalAccounts } from "./ExternalAccounts";
import { PeriodicWithdrawalCard } from "./PeriodicWithdrawalCard";
import { TranslatedText } from "../../../components/TranslatedText";
import { PendingWithdrawals } from "./PendingWithdrawals";
import { RouteAwareToggle } from "../../../components/route/RouteAwareToggle";
import { getNavLink } from "../../../hooks/useCountryUrls";
import {
  dataDanica,
  PendingKfWithdrawalResponse,
} from "../../../data/dataDanica";
import { WITHDRAWALS_PERIODIC_STORY_URL } from "../withdrawalPeriodic/WithdrawalPeriodicStory";
import { PendingAccountWithdrawals } from "./components/pendingWithdrawals/PendingAccountWithdrawals";
import HorizontalDivider from "../../../components/horizontalDivider/HorizontalDivider";
import { useIsPerson } from "../../../hooks/useIsPerson";
import { Snackbar, SNACKBAR_TYPES, Typography } from "@lysaab/ui-2";
import {
  CreateSavingsAccountWithdrawalSelectTypeCard,
  ExternalWithdrawalSelectTypeCard,
  InternalWithdrawalSelectTypeCard,
  VPWithdrawalSelectTypeCard,
} from "./components/withdrawalSelectType/WithdrawalSelectTypeCard";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { WITHDRAWAL_ISK_TO_SAVINGS_PAGE } from "../internalRequest/WithdrawalIskToSavingsStory";
import "./WithdrawalPage.scss";
import { CREATE_SAVINGS_ACCOUNT_URL } from "../../../countries/sweden/pages/createAccount/savingsAccount/CreateSavingsAccountStory";
import { WITHDRAWALS_REQUEST_PAGE } from "../request/WithdrawalStory";

export const WITHDRAWAL_PAGE_URL = "/withdrawals";

const messages = defineMessages({
  dividerText: {
    id: "withdrawals.horizontalDivider",
  },
});

interface Props {
  customMissingExternalAccounts?: (
    externalAccounts: ExternalAccount[]
  ) => JSX.Element;
}

export function WithdrawalPage({ customMissingExternalAccounts }: Props) {
  const intl = useIntl();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const isPerson = useIsPerson();
  const [externalAccounts, setExternalAccounts] = useState<ExternalAccount[]>(
    []
  );
  const [pendingPeriodicWithdrawals, setPendingPeriodicWithdrawals] = useState<
    PeriodicWithdrawal[]
  >([]);
  const [accounts, setAccounts] = useState<InvestmentAccount[]>([]);
  const [kfPendingWithdrawals, setKfPendingWithdrawals] = useState<
    PendingKfWithdrawalResponse[]
  >([]);
  const [pendingWithdrawals, setPendingWithdrawals] = useState<Withdrawal[]>(
    []
  );
  const [
    pendingSavingsAccountWithdrawals,
    setPendingSavingsAccountWithdrawals,
  ] = useState<ExternalPendingSavingsWithdrawalResponse[]>([]);
  const [hasSavingsAccount, setHasSavingsAccount] = useState<boolean>(false);

  const load = useCallback(() => {
    Promise.all([
      dataWithdrawals.getExternalAccounts(),
      dataWithdrawals.getPendingPeriodicWithdrawals(),
      dataWithdrawals.getPendingWithdrawals(),
      dataWithdrawals.getPendingSavingsAccountWithdrawals(),
      !isPerson ? dataDanica.getPendingKFWithdrawals() : Promise.resolve([]),
      dataAccounts.getAllAccounts(),
    ])
      .then(
        ([
          requestExternalAccounts,
          requestPendingPeriodicWithdrawal,
          pendingWithdrawals,
          pendingSavingsWithdrawals,
          requestPendingKFWithdrawals,
          allAccounts,
        ]) => {
          setExternalAccounts(requestExternalAccounts);
          setPendingPeriodicWithdrawals(requestPendingPeriodicWithdrawal);
          setPendingWithdrawals(pendingWithdrawals);
          setPendingSavingsAccountWithdrawals(pendingSavingsWithdrawals);
          setKfPendingWithdrawals(requestPendingKFWithdrawals);
          setAccounts(allAccounts.investmentAccounts);
          setStatus(Status.SUCCESS);
          setHasSavingsAccount(allAccounts.savingsAccounts.length > 0);
        }
      )
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [isPerson]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 500);
  }, [load]);

  useEffect(load, [load]);

  const removeAccount = useCallback((externalBankAccount: string) => {
    dataWithdrawals
      .deleteExternalAccount(externalBankAccount)
      .then((data) => {
        setExternalAccounts(data);
      })
      .catch(() => {});
    // we're lazy, maybe not the end of the world if an account cannot be removed
  }, []);

  const onPeriodicWithdrawalRemoved = useCallback(
    (periodicWithdrawal: PeriodicWithdrawal) => {
      dataWithdrawals.clearGetPendingPeriodicWithdrawals();
      setPendingPeriodicWithdrawals(
        pendingPeriodicWithdrawals.filter(
          (item) => item.accountId !== periodicWithdrawal.accountId
        )
      );
    },
    [pendingPeriodicWithdrawals]
  );

  const hasPendingWithdrawal =
    pendingWithdrawals.length > 0 ||
    pendingSavingsAccountWithdrawals.length > 0 ||
    pendingPeriodicWithdrawals.length > 0 ||
    kfPendingWithdrawals.length > 0;

  const InfoBoxComponent = () => {
    return (
      <Snackbar type={SNACKBAR_TYPES.INFO} icon>
        <FormattedMessage
          id="withdrawals.overview.selectType.external.info"
          values={{
            phone: (phoneNumber) => {
              return <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;
            },
            link: (linkText) => {
              return (
                <a href="https://www.lysa.se/kunskap/oro-pa-borsen-tank-sa-har-2025">
                  {linkText}
                </a>
              );
            },
          }}
        />
      </Snackbar>
    );
  };

  return (
    <Page className="withdrawal-page-wrapper">
      <div className="withdrawal-page-boundary">
        <Typography type="h1">
          <TranslatedText id="withdrawals.header" />
        </Typography>

        <Retry retry={retry} status={status}>
          <div className="withdrawal-page">
            <RouteAwareToggle path={getNavLink(WITHDRAWALS_REQUEST_PAGE)}>
              <InfoBoxComponent />
              {isPerson ? (
                <>
                  <RouteAwareToggle
                    path={getNavLink(WITHDRAWAL_ISK_TO_SAVINGS_PAGE)}
                  >
                    {hasSavingsAccount ? (
                      <InternalWithdrawalSelectTypeCard />
                    ) : (
                      <RouteAwareToggle
                        path={getNavLink(CREATE_SAVINGS_ACCOUNT_URL)}
                      >
                        <CreateSavingsAccountWithdrawalSelectTypeCard />
                      </RouteAwareToggle>
                    )}
                    <HorizontalDivider
                      text={intl.formatMessage(messages.dividerText)}
                    />
                  </RouteAwareToggle>
                  <ExternalWithdrawalSelectTypeCard />
                </>
              ) : (
                <>
                  <VPWithdrawalSelectTypeCard />
                </>
              )}
              <HorizontalDivider />
            </RouteAwareToggle>
            <div>
              <ExternalAccounts
                externalAccounts={externalAccounts}
                removeAccount={removeAccount}
                customMissingExternalAccounts={customMissingExternalAccounts}
              />
            </div>

            {hasPendingWithdrawal && (
              <>
                <HorizontalDivider />
                <div>
                  <Typography type="h3">
                    <TranslatedText id="withdrawals.pending.header" />
                  </Typography>
                  <PendingAccountWithdrawals />
                  <PendingWithdrawals
                    accounts={accounts}
                    pendingPeriodicWithdrawals={pendingPeriodicWithdrawals}
                    onPeriodicWithdrawalRemoved={onPeriodicWithdrawalRemoved}
                    kfPendingWithdrawals={kfPendingWithdrawals}
                  />
                </div>
              </>
            )}

            <RouteAwareToggle path={getNavLink(WITHDRAWALS_PERIODIC_STORY_URL)}>
              <HorizontalDivider />
              <PeriodicWithdrawalCard />
            </RouteAwareToggle>
          </div>
        </Retry>
      </div>
    </Page>
  );
}
